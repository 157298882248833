@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 350px;
       border-radius: 10px;
    background-color: whitesmoke;
    transform: translateX(100px);
    opacity: 0;
    transition: opacity 250ms, transform 150ms;
    margin-top: 10px;
  }
  
  .card.show {

    transform: translateX(0);
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 350px;
    border-radius: 10px;
    background-color: whitesmoke;
    margin-top: 10px;
  }

  .card.past{

    transform: translateX(0);
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 350px;
    border-radius: 10px;
    background-color: lightgreen;
    margin-top: 10px;
  }




  .leftBox{
    flex: 0.8;
    display: flex;

  }

  .profileImgDiv{
    width: 80px;
    height: 80px;
  }
  
  .profileImg{
    margin-left: 5px;
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }


  .rightBox{
    flex: 1.9;
    display: flex;
    flex-direction: column;
  
  }
  
  .rightBoxTop{
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }
  
  .rightBoxName{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
  }

.rightBoxLanguage{
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 200;
}

  .rightBoxType{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 300;
  }

  .rightBoxBottom{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-right: 10px;
  }

  .rightBoxDate{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 200;
  }

  .rightBoxLessons{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 300;
    
  }

  .timeslot{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 900;
  }

  .timesfreeSpace{

  }

  .reservedLesson{
    text-align: center;
    background-color: lightcoral;
  }

  .confirmedLesson{
    
  }

  .completedLesson{
   
    color: white;
  }

.buttons{
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  gap:20px;
  margin-bottom:10px ;
}

.CancelBtn{
  width: 110px;
  height: 25px;
  border-radius: 10px;
  background-color: red;
  color: white;
  cursor: pointer;
}


.CancelBtn:hover{
  background-color: rgb(183, 75, 75);

}

.RebookBtn{
  cursor: pointer;
  width: 110px;
  height: 25px;
  border-radius: 10px;
  background-color: green;
  color: white;
}

.RebookBtn:hover{
  background-color: lightgreen;
  color: black;
}


.error{
color: red;
}


  @media (max-width: 767px) {
    .card {
      width: 250px;


    }
    .card.show {

      width: 250px;
 
    }
    
}