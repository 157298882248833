  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,100&display=swap');

.mainDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

  
  .completionDiv {
    margin-top: 100px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    opacity: 0;
    transition: opacity 1s;
  }
  .completionH1{
    font-weight: 100;
  }
  
  .completionDiv.show {
    opacity: 1;
  }
  
  .findTeacher {
    margin-top: 60px;
  }
  
  .findTeacherButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    border-radius: 10px;
    cursor: pointer;
    width: 180px;
    height: 50px;
  }
  



@media (max-width: 768px) {
    .completionDiv {
      font-size: 16px;

    }
  }
  