@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');

.card {
    margin-left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 350px;
    height: auto;
    border-radius: 10px;
    background-color: darkblue;
    transform: translateX(100px);
    opacity: 0;
    transition: opacity 250ms, transform 150ms;
    margin-top: 10px;
  }
  
  .card.show {

    transform: translateX(0);
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 350px;
    height: auto;
    border-radius: 10px;
    background-color: darkblue;
    margin-top: 10px;
  }

  .leftBox{
    flex: 0.7;
    display: flex;

  }

  .profileImgDiv{
    width: 80px;
    height: 80px;
  }
  
  .profileImg{
    margin-left: 5px;
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }


  .rightBox{
    flex: 1.9;
    display: flex;
    flex-direction: column;
  
  }
  
  .rightBoxName{
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 13px;
    font-weight: 600;

  }

  .rightBoxType{
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 13px;
    font-weight: 300;
  }

  .rightBoxLessons{
    display: flex;
    justify-content: space-around;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;

  }

  .confirmButton{
    width: 80px;
      padding: 5px;
    background-color: green;
    color: #eee;
    border-radius: 5px;
  }

  .rejectLesson{
    width: 80px;
    padding: 5px;
    background-color: red;
    color: #eee;
    border-radius: 5px;
  }
  .reviewTextarea {
    width: 250px;
    height: 60px;
    padding: 10px; /* Add padding for spacing inside the textarea */
    border: 1px solid #ccc; /* Add a border for a cleaner look */
    border-radius: 4px; /* Add rounded corners */
    font-size: 16px; /* Adjust font size to your preference */
    resize: none; /* Disable textarea resizing */
    outline: none; /* Remove the default outline on focus */
    transition: border-color 0.2s; /* Add a smooth transition effect for border color */
  
    /* Optional: Add a box-shadow for a slight 3D effect on focus */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  
    /* Optional: Define a background color */
    background-color: #f5f5f5;
  }
  
  .reviewTextarea:hover,
  .reviewTextarea:focus {
    border-color: #007bff; /* Change border color on hover and focus */
  }
  
  @media (max-width: 767px) {

  .card {
    width: 250px;

  }
  
  .card.show {

    width: 250px;

  }
}