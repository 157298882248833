@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');

* {
    margin: 0;
    padding: 0;
  
  }

.walletMainDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.walletTopDiv{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.walletTopDivH2{
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 300;
}

.walletMiddleDiv{
    display: flex;
    background-color: whitesmoke;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    height: 50px;
}

.walletMiddleDivButton{
    cursor: pointer;
}

.walletMiddleDivBuy{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    width: 180px;
    height: 30px;
    border-radius: 10px;
    background-color: red;
    color: white;
    font-size: 18px;
    font-weight: 300;
    
}

.walletMiddleDivButton{
    cursor: pointer;
}

.walletMiddleDivWithdraw{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        width: 180px;
        height: 30px;
        border-radius: 10px;
        background-color: green;
        color: white;
        font-size: 18px;
        font-weight: 300;
    }       
 

.walletBottomDiv{
margin-top: 40px;
}