  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,100&display=swap');

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.errorLogo{
    position: absolute;
    top:30px;
    left:30px;
}

.errorHeadline{
    font-size: 52px;  
    font-family: 'Cinzel', serif;
    font-weight: 400;
    padding: 1.5em 0 0.3em;
    line-height: 1.07692;
}

.errorHeadline2{
    text-align: center;
    font-size: 24px;  
    font-family: 'Cinzel', serif;
    font-weight: 400;
    padding: 1.5em 0 0.3em;
    line-height: 1.07692;
}



@media screen and (max-width:768px) {

    .errorEmoji{
        width: 50%;
        height: 50%;
    }    

    .errorLogo{
        position: absolute;
        top:20px;
        left:20px;
    }
    .errorLogo img{
        width: 100px;
        height: 100px;
       }

    .errorHeadline{
        font-size: 42px;  
        font-family: 'Cinzel', serif;
        font-weight: 400;
        padding: 1.5em 0 0.3em;
        line-height: 1.07692;
    
    
    }
    .errorHeadline2{
        text-align: center;
        font-size: 18px;  
        font-family: 'Cinzel', serif;
        font-weight: 400;
        padding: 1.5em 0 0.3em;
        line-height: 1.07692;
    }
    
    }