@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');

:root {
  --light-grey: #f6f9fc;
  --dark-terminal-color: #0a2540;
  --accent-color: #635bff;
  --radius: 3px;
}

.buyCreditMainDiv{
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgreen;
}

.title{
    font-family: 'Poppins', sans-serif; 
    font-weight: 200;
    font-size: 18px; 
    
}

.titleAmount{
  margin-top: 10px;
  font-size: 18px;
  font-weight: 900;
}

.creditsToBuyDIV{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.creditBoxes{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: gold;
}

.taxText{
  font-family: 'Poppins', sans-serif; 
  font-size: 14px;
  
}
.main {
  width: 480px;
}

.form > * {
  margin: 10px 0;
}

.form button {
  background-color: var(--accent-color);
}

.form button {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
.form button:hover {
  filter: contrast(115%);
}
.form button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
.formbutton:disabled {
  opacity: 0.5;
  cursor: none;
}

.form input,
.form select {
  display: block;
  font-size: 1.1em;
  width: 100%;
  margin-bottom: 10px;
}

.form label {
  display: block;
}

.form a {
  color: var(--accent-color);
  font-weight: 900;
}

.form small {
  font-size: 0.6em;
}

.form fieldset,
.form input,
.form select {
  border: 1px solid #efefef;
}

#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 0.7em;
}


@media screen and (max-width: 768px) {
  .main {
    width: 100%;
  }

  .creditsToBuyDIV {
    width: 100%;
    justify-content: space-between;
  }

  .creditBoxes {
    width: 30%;
    height: 80px;
  }

  .form input,
  .form select {
    font-size: 1em;
  }
}


@media screen and (max-width: 480px) {
  .creditsToBuyDIV {
    flex-direction: column;
  }

  .creditsToBuyDIV{
    width: 300px;
    height: 200px;
    flex-wrap: wrap;
 
  }
  .creditBoxes{

    width: 70px;
    height: 70px;

  }
  
}
