@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');

dialog[open] {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  display: flex;
  justify-content: center;
  z-index: 100000;
}

dialog[open]::backdrop {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
}

dialog[open] > div {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  z-index: 100000;
}

.profileMainContainer{
    z-index: 100000;
  position: absolute;
  display: grid;
    width: 98vw;
    height: 100vh;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 180px 140px 1fr;
  
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.profileImgContainer{
  margin-top: 10px;
    grid-column-start: 1;
    grid-column-end: 2;

}

.profileImgContainerFlexBox{
    position: absolute;
    margin-top: 10px;
    margin-left: 150px;
    height: auto;
}

.profileImgContainerimgDiv{
    width: 140px;
    height: 140px;
}

.profileImgContainerImg{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profileClassesData{
     grid-column-start: 2;
    grid-column-end: 4;
    margin-left: 50px;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:10px;
    padding-right: 15px;
    padding-left: 5px;
}

.profileClassesDataMember{
     margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.profileClassesDataH1{
    font-size: 25px;
    font-weight: 400;
    color: gray;

}

.profileClassesDataH2{
     font-size: 18px;
    font-weight: 300;
}
.profileLanguagesContainer{

    padding-left: 20px;
    grid-column-start: 1;
    grid-column-end: 4;
    border-bottom: 1px solid black;
}



.profileForm {
    
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
     margin-top: 100px;
    gap: 20px;
  }
  
 

  .oldPasswordInputContainerInput {
    width: 300px;
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: lightblue; 
    transition: background 0.3s ease; 
  }
  
  .oldPasswordInputContainerInput:focus {
    background: white; 
  }
  
  

.oldPasswordErrorContainer{
    color:red;
    font-size: 14px;
    font-weight: 100;
   }

.newPasswordInputContainer{
    width: 300px;
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: lightblue; 
    transition: background 0.3s ease; 
 }

 .newPasswordInputContainer:focus {
    background: white; 
  }
  

.newPasswordErrorContainer{
    color:red;
    font-size: 14px;
    font-weight: 100;
 
  }  

.confirmPasswordInputContainer{
  width: 300px;
  height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: lightblue; 
    transition: background 0.3s ease; 
 }

 .confirmPasswordInputContainer:focus {
    background: white; 
  }

.confirmPasswordErrorContainer{
    color:red;
    font-size: 14px;
    font-weight: 100;
 
 }
 


 .phoneNumberContainer {
  width: 300px;
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: lightblue;
  transition: background 0.3s ease;
}



.buttons{
    display: flex;
    gap:20px
  }

.updateBtn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .updateBtn:hover {
    background-color: #408c40; /* Darker shade of the original color */
  }
  
  .closeBtn {
    background-color: gray;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .closeBtn:hover {
    background-color: rgb(37, 36, 36);
  }

  .profileUserDataContainer{
    margin-left: 50px;
    margin-top: 50px;
    grid-column-start: 1;
    grid-column-end: 2;   
   
 }


 .profileUserDataUpdateContainer{
  padding-top: 10px;
  padding-left: 80px;
  background-color: whitesmoke;
  grid-column-start: 1;
  grid-column-end: 4;
}


@media (max-width: 767px) {
    .profileMainContainer{
        grid-template-columns: 1fr;
        grid-template-rows: 150px 100px 1fr 1fr;
    }

    .profileImgContainer{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
         }

         .profileLanguagesContainer{
            grid-column-start: 1;
            grid-row-start: 2;
            grid-row-end: 3;
     
        }
        
         .profileUserDataUpdateContainer{
           
            grid-column-start: 1;
            grid-row-start: 3;
            grid-row-end: 4;
         
        }

        .profileUserDataContainer{
           
         display: flex;

         justify-content: center;
         margin-right: 20px;
         align-items: center;
    
       
      }

    .profileClassesData{  
      margin-top: 20px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 5;
        margin-left: 0px;
       
    }

    .profileClassesDataMember{
    
       align-items: center;
   }

   .profileClassesData{
  align-items: center;
   justify-content: center;

}


    
}