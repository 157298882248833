

.layoutContainer {
  max-width: 100vw;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .content {
    flex: 1;

  }
  