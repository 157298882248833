@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Sonsie+One&display=swap');

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
  .timeText {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 300;
  }

  .imageUser{
    width: 170px;
    height: 170px;
  }

  .imagePhone{
    width: 130px;
    height: 130px;
  }


  .users{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 250px;
    border-radius: 50%;
    margin-top: 20px;
    background-color: transparent;
    color:black;
    cursor: pointer;
  }

  .users:hover {
    animation: fadeIn 2s ease-in-out forwards; /* Animation property with a 2-second duration */
  }
  
  @keyframes fadeIn {
    from {
      background-color: yellow;
      color:black;
    }
    to {
      background-color: purple;
      color:white;
    }
  }

  .usersText{
    font-family: 'Sonsie One', cursive;
    font-size: 13px;
  }

  .productsText{
    font-family: 'Sonsie One', cursive;
    font-size: 13px;
  }




  .products{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 250px;
    border-radius: 50%;
    margin-top: 20px;
    background-color: transparent;
    color:black;
    cursor: pointer;
  }

  .products:hover{
    animation: fadeIn 2s ease-in-out forwards; /* Animation property with a 2-second duration */
}

@keyframes fadeIn {
  from {
    background-color: yellow;
    color:black;
  }
  to {
    background-color: purple;
    color:white;
  }
  }