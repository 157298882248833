@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 350px;
    height: 100px;
    border-radius: 10px;
    background-color: whitesmoke;
    transform: translateX(100px);
    opacity: 0;
    transition: opacity 250ms, transform 150ms;
    margin-top: 10px;
  }
  
  .card.show {

    transform: translateX(0);
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 350px;
    height: 100px;
    border-radius: 10px;
    background-color: whitesmoke;
    margin-top: 10px;
  }

  .leftBox{
    flex: 1.1;
    display: flex;

  }

  .profileImgDiv{
    width: 80px;
    height: 80px;
  }
  
  .profileImg{
    margin-left: 5px;
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }


  .rightBox{
    flex: 1.9;
    display: flex;
    flex-direction: column;
  
  }
  
  .rightBoxName{
    font-family: 'Poppins', sans-serif;
    font-size: 23px;
    font-weight: 600;

  }

  .rightBoxType{
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 300;
  }

  .rightBoxLessons{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-align: end;
  }

  @media (max-width: 767px) {

  .card {
    width: 250px;

  }
  
  .card.show {

    width: 250px;

  }
}