@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');

dialog[open] {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  }
  
  dialog[open]::backdrop {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  }
  
  dialog[open] > div {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

.title{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    
}

.text{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
}

  .loginForm {
    font-family: 'Poppins', sans-serif;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="email"],
  input[type="text"],
  input[type="password"] {
    padding: 10px;
      border: 1px solid #ccc;
    border-radius: 4px;
    width: 280px;
  }
  
  .emailInputContainer {
     display: flex;
     flex-direction: column;
  }

  .WrongOTP{
    color: red;
  }

  .emailErrorContainer{
    color:red;
    font-size: 14px;
    font-weight: 100;
 
  }

  .passwordErrorContainer{
    color:red;
    font-size: 13px;
    font-weight: 100;
 
  }

  .passwordInputContainer {
    display: flex;
    flex-direction: column;

 }
  .buttons{
    display: flex;
    gap:20px
  }
  
  .sendBtn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .sendBtn:hover {
    background-color: #408c40; /* Darker shade of the original color */
  }
  
  .closeBtn {
    background-color: gray;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .closeBtn:hover {
    background-color: rgb(37, 36, 36);
  }
  
  .loginSpan {
    font-size: 14px;
  }
  
  .signupLink,
  .forgotPasswordLink {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  