@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');


.mainDiv{
    display: flex;
    align-items: center;
    justify-content: center;    
    flex-direction: column;
    margin-top: 10px;
    position: absolute,;
}


    .goUp{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-top: 15px;
        border-radius: 10px;
         background-color: black;
        color: white;
        width: 150px;
        height: 30px;
        font-size: 20px;
        margin-bottom: 30px;
    }


.filtersBack{
    position: fixed;
    top:250px;
    right: 150px;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    background-color: #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.filtersBack:hover{
    background-color: lightgray;

}

.noTeacherText{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}


@media (max-width: 1200px) {

    .filtersBack{
        position: fixed;
        top:280px;
        right: 60px;
        width: 100px;
        height: 50px;
        background-color: #e5e7eb;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    
}

@media (max-width: 767px) {

    .filtersBack{
        position: fixed;
        top:280px;
        right: 10px;
        width: 70px;
        height: 50px;
        background-color: #e5e7eb;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    
}