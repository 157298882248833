
  @import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Montserrat:ital,wght@0,100;0,400;0,500;0,600;1,100;1,200&family=Poppins:wght@400;500&family=Rubik+Vinyl&display=swap');

  :root {
    --background-color: #f0f0f0;
  }

  *{margin: 0;
    padding: 0;
    
  }

a {
    color: inherit;
    text-decoration: none;
  }
  

.findTeacherTop{
  display: flex;
  flex-direction: column;
  position: absolute;
 }

.findTeacherFilters{
  display: flex;
  top: -0.5px;
  height: 80px;
  width: 780px;
  background-color: white;
  position: sticky;
  padding-top:10px;
  z-index: 1500;

}

.teachertypecontainer{
  position: absolute;
  top: 10px;
}
  
.sliderContainer{
  width: 250px;
  position: absolute;
  right: 15px;
}

.mainContainer{
    margin-top: 20px;
    margin-left: 100px;
    display: flex;


  }

  .container{
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    margin-top: 10px;
}



.videoDiv{
  position: fixed;
  top:150px;
  left:60%;
  border-radius: 10px;
}

.footerDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.goUpBtn{
  margin-top: 15px;
  margin-bottom: 15px;
  width: 150px;
  height: 25px; 
  background-color: gray;
  color: white;
  border-radius: 10px;
}


.goUpBtn:hover{
  background-color: lightgray;
  color: black;
}


@media only screen and (max-width: 1310px) {
.videoDiv{
display: none;
}
}


.searchBarContainer{
      position: absolute;
      left : 20px;
}
.teachertypecontainer{
    position: absolute;
    left : 260px;
  
  }
.sliderContainer{
  position: absolute;
  left : 560px;
  bottom: 14px;
}

  .findTeacherComponent{
    margin-top: 10px;
  }


@media only screen and (max-width: 800px) {
.videoDiv{
display: none;

}

}




/* Pagination Container */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Pagination List */
.paginationList {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

/* Pagination Item */
.paginationItem {
  cursor: pointer;
  padding: 5px 10px;
  background-color: #f2f2f2;
  color: #333;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  /* Add hover effect */
  &:hover {
    background-color: #ccc;
  }
}

/* Active Pagination Item */
.activePage {
  background-color: #007bff;
  color: #fff;
}

/* Add some spacing between pagination and content */
.mainContainer {
  margin-bottom: 40px;
}



@media only screen and (max-width: 767px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    margin-left: 30px;
  }

  .findTeacherFilters {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
  }

  .sliderContainer {
    display: flex;
    justify-content: center;
    position: fixed; /* Change from 'absolute' to 'fixed' */
    left: 55%;
    transform: translateX(-50%);
  }

  .searchBarContainer {
    background-color: lightblue;
    position: absolute;
    left: 20px;
  }

  .teachertypecontainer{
    background-color: lightblue;
    position: absolute;
   }
}
