@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');


.mainTeacherDiv{
    margin-top: 50px;
    padding-bottom: 30px;
    height: auto;
    background-color: #e5e7eb;
    display: flex;
    flex-direction: column;
  
}

.mainTeacherTextDiv{
    margin-top: 25px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    font-weight: 100;
}

.teacherPresentations{
    display: flex;
    justify-content: center;

    
}

.teacherBox{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-width: 320px;
    width: 320px;
    min-height: 350px;
    width: 350px;
    background-color: white;
    border-radius: 10px;
  
}
.teacherBoxTop{
    border-radius: 10px 10px 0 0;
    height: 200px;
    background-color: beige;
    display: flex;
    justify-content: center;
    align-items: center;
}

.teacherBoxTopImage{
    width: 200px;
    height:200px;

}

.profile{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.teacherBoxBottom{
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.teacherBoxName{
    margin-top: 15px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    line-height: 0.8;
}
.teacherType{
    color: gray;
}

.teacherLanguage{
    font-family: 'Poppins', sans-serif;
    margin-top: 10px;
    font-size: 15px;
}

.teacherLanguageLevel{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1px;
    color: rgb(63, 177, 171);
}

.teacherPriceText{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    margin-top: 15px;
    color: gray;

}

.teacherPrice{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 900;
}