  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Poppins:ital,wght@1,100&display=swap');

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.spinner{}

.oneMoment{
    margin-top: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
}

.text{
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
}