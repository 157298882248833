.leftBottomDiv {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 100%;
    height: auto;
    background-color: white;
    overflow: hidden;
  }
  
  .mainBox {
    cursor: pointer;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 120px;
    height:50px; /* Change to 'auto' to accommodate varying content height */
    background-color: lightgray;
  }
  
  .img {
    width: 45px;
    height: 45px;
    flex-shrink: 0; /* Prevent the image from shrinking */
  }
  
  .profileImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  