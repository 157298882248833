@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');

.mainDiv{
    background-color: white;

}

.typing{
    left: 100px;
    top: -10px;
    position: absolute;
    color: red;
}

.text{
    text-align: center;
}
.chatDivMainContainer{
    margin-top: 20px;
    
}
.chatDivContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

  }
  
.mainChatBox{
    
    display: flex;
    flex-direction: row;
    overflow: auto;
    overflow-wrap: break-word;
        }

.mainChatBoxLeftSide{
    display: flex;
     flex-direction: column;
    max-width: 280px;
    max-height: 820px;
    border-radius: 5px 0 0 5px;
}

.mainChatBoxLeftSideTop{
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f9fc;
    border-radius: 5px 0 0 0;
    min-height: 50px;
    padding-left: 5px;
    padding-right: 5px;
}

.mainChatBoxLeftSideTopInput {
    width: 220px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;
    outline: none;
    font-size: 14px;
    transition: border-color 0.3s ease;
    }

    .mainChatBoxLeftSideTopInput:focus {
    border-color: #5c9ded;
    }

    .mainChatBoxLeftSideBottom {
        
         flex-grow: 1;
        overflow-y: auto;
       
        }
       

    .mainChatBoxLeftSideBottomBoxTrue {
        display: flex;
        background: #f1f1f1;
        margin-bottom: 2px;
        height: 40px;
        padding: 10px;
        position: relative;
        }

.mainChatBoxLeftSideBottomBox {
    display: flex;
    background-color: #f1f1f1;
    margin-bottom: 2px;
    height: 40px;
    padding: 10px;
    position: relative;


}



        .mainChatBoxLeftSideBottom::-webkit-scrollbar {
            width: 8px; /* Width of the scrollbar */
            }

        .mainChatBoxLeftSideBottom::-webkit-scrollbar-track {
        background: #f1f1f1; /* Background color of the scrollbar track */
        }

        .mainChatBoxLeftSideBottom::-webkit-scrollbar-thumb {
        background: #888; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Border radius of the scrollbar thumb */
        }

        .mainChatBoxLeftSideBottom::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color of the scrollbar thumb on hover */
        }


        .mainChatBoxLeftSideChatAreaStudentMain{

            display: flex;
            height: 40px;
            background-color: lightgray;
            padding-left: 5px;
            padding-right: 5px;
            margin-bottom: 2px;
        }

        .noReadMessage {
            display: flex;
            height: 40px;
            background-color: lightgray;
            padding-left: 5px;
            padding-right: 5px;
            margin-bottom: 2px;
            animation: colorChange 5s linear infinite; /* Adjust the duration as needed */
        }
        
        @keyframes colorChange {
            0% {
                background-color: green;
            }
            50% {
                background-color: blue;
            }
            100% {
                background-color: green;
            }
        }
        
    .mainChatBoxLeftSideBottomBoxImg{
        flex:1;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .mainChatBoxLeftSideBottomBoxImgDIV{
        width: 30px;
        height: 30px;
    }

    .mainChatBoxLeftSideBottomBoxImgTag{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }

    .mainChatBoxLeftSideChatAreaStudent{
        flex:3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 5px;
    }

    .mainChatBoxLeftSideChatAreaStudentBoxDate{
        font-size: 12px;
    }

    .mainChatBoxLeftSideBottomBoxName{
        font-size: 13px;
    }


.mainChatBoxRightSideSwitch{
display: flex;
align-items: center;
justify-content: flex-end;

}

.calendarIcon {
    margin-top: 5px;
    margin-right: 50px;
    position: relative;
    cursor: pointer;
  }
  
  .calendarIcon:hover::after {
    content: "calendar";
    position: absolute;
    top: 0px;
    left: 25px;
    background: #000;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
  }
  

.switchIcon{
    display: none ;
    font-size: 30px;
    margin-top: 5px;
    margin-left: 50px;
    font-weight: 900;
  position: relative;  
  cursor: pointer;
}
.switchIcon:hover::after {
    content: "your teachers";
    position: absolute;
    margin-top: 5px;
    top: 0px;
    left: 25px;
    background: #000;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
  }


.mainChatBoxRightSideChatArea{
    position: relative;
    display: flex;
      min-height: 150px;
    font-size: 14px;
    font-weight: 600;
    flex-grow: 1;
    overflow-y: auto;
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 5px;
    padding-bottom: 100px;
    max-height: 500px;
    overflow: auto; /* Add this line to enable scrolling */
    background-color: #f6f9fc;

}

.mainChatBoxRightSideChatAreaStudent{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;

    border: 1px solid black;
    display: flex;

}


.mainChatBoxRightSideChatAreaStudentMain{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    display: flex;
       
}

.mainChatBoxRightSideChatAreaTeacherMain{
    padding-bottom: 5px;
    margin-bottom: 5px;
}



.mainChatBoxRightSideChatAreaStudent {

    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
    margin-top: 10px;
    max-width: 550px;
    margin-left: 10px;
    margin-right: 10px;
    background: lightgray;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-radius: 0 10px 10px 10px;
    
            
}

.mainChatBoxRightSideBottomBoxDate{
    font-weight: 300;
    font-size: 10px;
    margin-top: 5px;
    padding-right: 5px;
    line-height: 10px;
}

.mainChatBoxRightSideChatAreaStudentPtag {
    margin-top: 10px;
    max-width: 500px;
    margin: 0;
    word-wrap: break-word; /* Added property */
}
  
.mainChatBoxRightSideBottomBoxImg{
         display: flex;
        align-items: center;
        
    }
 .mainChatBoxRightSideBottomBoxImgTag {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }


.mainChatBoxRightSideChatAreaTeacherMain{
        display: flex;
        margin-left: 50px;
        margin-right: 50px;
       
}

.mainChatBoxRightSideChatAreaTeacher{
   
   flex-direction: column;
   max-width: 550px;
    margin-top: 5px;;
    margin-left: 10px;
    margin-right: 10px;
    background: gray;
    color: white;
    padding-left: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    border-radius: 0 10px 10px 10px;
    overflow: auto;
    word-wrap: break-word;

}

.mainChatBoxRightSideChatAreaTeacherPtag{
    margin-top: 5px;

}


.mainChatBoxRightSideChatArea::-webkit-scrollbar {
            width: 8px; /* Width of the scrollbar */
            }

        .mainChatBoxRightSideChatArea::-webkit-scrollbar-track {
        background: #f1f1f1; /* Background color of the scrollbar track */
        }

        .mainChatBoxRightSideChatArea::-webkit-scrollbar-thumb {
        background: #888; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Border radius of the scrollbar thumb */
        }

        .mainChatBoxRightSideChatArea::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color of the scrollbar thumb on hover */
        }

        .chatCalendar{
            width: 35px;

        }

        .chatInputButton {
            display: flex;
            align-items: flex-start;
            margin-top: 20px;
            margin-bottom: 20px;
            position: relative;
        }
        
        .chatInput {
            margin-left: 10px;
            margin-top: 10px;
            flex-grow: 1;
            width: 500px;
            height: 100px; /* Adjust the height as needed */
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 14px;
            resize: none;
        }
        

  .sendChat {
    width: 100px;
    height: 30px;
    margin-top: 10px;
    margin-left: 8px;
    padding: 8px 16px;
    background-color: rgb(213, 53, 53);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }

  .sendChat:hover {
    background-color: red;
  }

  .mainChatBoxRightSideFlex{
    display: flex;
    margin-right: 50px;
    padding-right: 10px;
    background-color: lightgray;
    flex-direction: column;

  }

  .hidingArrow{
    display: none;
   }


.firstMessageQuestionary{
    display: flex;
    flex-direction: column;
color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 300;
    padding: 5px;
}

@media screen and (max-width:768px) {
.mainChatBox{
   margin-left: 5px;
    margin-right: 5px;
    overflow: auto;
    overflow-wrap: break-word;
   
        }
.mainChatBoxRightSideChatArea{
    max-width: 420px;
        }
.mainChatBoxRightSideChatAreaTeacherMain{
    padding-bottom: 5px;
            display: flex;
            max-width: 300px;
            margin-left: 50px;
            margin-right: 50px;
            
           
    }


.mainChatBoxRightSideTop{
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 50px;
    min-width: inherit;
       border-radius: 5px 5px 0 0;
}

.mainChatBoxRightSideChatAreaTeacher{
   
    flex-direction: column;
    max-width: 350px;
     margin-top: 10px;;
     margin-left: 10px;
     margin-right: 10px;
       padding-left: 10px;
     padding-right: 15px;
     padding-bottom: 10px;
     border-radius: 0 10px 10px 10px;
     overflow: auto;
     overflow-wrap: break-word;
 
 }

.chatInputButton {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

}

        
.chatInput {
    margin-top: 10px;
   flex-grow: 1;
    width: 400px;
    height: 100px; /* Adjust the height as needed */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    resize: none;
}


.hidingArrow{
    display: block;
    }


.mainChatBoxRightSideFlex{
    display: flex;
    margin-right: 0px;
    max-width: 400px;
}

.mainChatBoxRightSideHidden{
    display: none;
}

.mainChatBoxLeftSide{
    display: none;
 
}

.mainChatBoxLeftSideVisible{
    display: block;
 
}

.switchIcon{
    display: block ;
    font-size: 30px;
}

.mainChatBoxRightSideSwitch{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    }
}