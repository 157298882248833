.btn{
    width: 100%;
    height: 30px;
    background-color: blue;
    color: white;
}
.btn:hover{
    width: 100%;
    height: 30px;
    background-color: lightblue;
    color: black
}

.btnNone{
    display: none;
}