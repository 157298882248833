@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');


.mainContainer{
    display: flex;
    justify-content: center;
    position: relative;
}

.mainContainerFixed{
position: fixed;
}

.mainDiv{
    display: flex;
    align-items: center;
    justify-content: center;    
    flex-direction: column;
    margin-top: 10px;
    position: absolute,;
}

.goUp{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin-top: 15px;
    border-radius: 10px;
     background-color: black;
    color: white;
    width: 150px;
    height: 30px;
    font-size: 20px;
    margin-bottom: 30px;
}

.filters{
    position: fixed;
    top:150px;
    right: 150px;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    background-color: #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.filtersBack{
    position: fixed;
    top:250px;
    right: 150px;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    background-color: #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.filters:hover{
    background-color: lightgray;

}

.filtersBack:hover{
    background-color: lightgray;

}

.noLessonText{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

@media (max-width: 1200px) {
    .filters{
        position: fixed;
        top:200px;
        right: 60px;
        width: 100px;
        height: 50px;
        background-color: #e5e7eb;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .filtersBack{
        position: fixed;
        top:280px;
        right: 60px;
        width: 100px;
        height: 50px;
        background-color: #e5e7eb;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    
}

@media (max-width: 767px) {
    .filters{
        position: fixed;
        top:200px;
        right: 10px;
        width: 70px;
        height: 50px;
        background-color: #e5e7eb;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .filtersBack{
        position: fixed;
        top:280px;
        right: 10px;
        width: 70px;
        height: 50px;
        background-color: #e5e7eb;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    
}