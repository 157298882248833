@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');

.testMainDiv{
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
}

.title{
    text-align: center;
     margin-bottom: 15px;
    text-transform: uppercase;

}

.questions{
   
    display: grid;
    gap: 5px;
}

.questionsLoop{
 
    font-size: 11px;
}
.answersLoop{
    display: flex;
    align-items: center;
    
    margin-left: 10px;
    gap:15px;
    font-size: 15px;
    color: gray;
   
}
.answersLoopWrong{
    display: flex;
    align-items: center;
   
    gap:15px;
    font-size: 11px;
     color: red
}
.answersLoopH2wrong{
    color: red;
    font-weight: 900;
    font-size: 14px;
}


.submitButton{
    margin-top:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4caf50;
    color: white;
    width: 120px;
    height: 40px;
    border-radius: 5px;
}

.congratulationButton{
    margin-top:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gold;
    width: 200px;
    height: 50px;
    border-radius: 5px;
    font-size: 14px;
}

.notPassedButton{
    margin-top:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
    color: white;
    width: 180px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
}
.results{
    margin-top: 15px;
 
}
.resultsH2{
    color: #4caf50;
    font-size: 20px;;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
}


.answersLoop input[type="radio"] {
    margin-right: 10px;
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid gray;
    outline: none;
    margin-bottom: 5px;  
  cursor: pointer;
  }
  
  .answersLoop input[type="radio"]::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: gray;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease, background-color 0.3s ease;
  }
  
  .answersLoop input[type="radio"]:checked::before {
    opacity: 1;
  }
  
  .answersLoop input[type="radio"]:hover {
    border-color:gray;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: gray;
  }
  .answersLoop input[type="radio"]:hover::before {
    background-color: gray;
  }