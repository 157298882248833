
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins:ital,wght@0,200;0,400;1,200&display=swap');


.mainTeacherZoneDiv{
    background-color: #f5f6f9;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 100px 60px 60px 60px 60px 60px 60px 120px 1fr  ;
    gap: 10px;
  
}
.mainTeacherZoneDivFixed{
    position: fixed;
    background-color: #f5f6f9;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 100px 60px 60px 60px 60px 60px 60px 120px 1fr  ;
    gap: 10px;
  
}

.mainTeacherZoneDivName{
  
    font-family: 'Poppins', sans-serif;
    grid-row-start: 1;
    grid-row-end:2;
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.mainTeacherZoneDivID{
    border-radius: 5px;
    width: 270px;
    height: 20px;
    background-color: lightgray;
    font-size: 13px;   
    display: flex;
    align-items: center;
    padding-left: 10px;
    justify-content: left;
}

.mainTeacherZoneTeacherName{
    font-size: 16px;
    font-weight: 900;
    margin-top: 5px;
    color: gray;
    }

.mainTeacherZoneTeacherSince{
    color: gray;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.mainTeacherZoneSchedules{
    border-radius: 10px;
    grid-row-start: 2;
    grid-row-end:3;
    grid-column-start: 1;
    grid-column-end: 2;
    color: gray;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}

.mainTeacherZoneSchedulesBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: purple;
    width: 80px;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    margin-bottom: 10px;
  }


  .mainTeacherZoneSchedulesBtn:hover {
    background-color: rgb(181, 26, 181); /* Darker shade of the original color */
  }

.mainTeacherZoneUpcomingLessons{
    border-radius: 10px;
    grid-row-start: 3;
    grid-row-end:4;
    grid-column-start: 1;
    grid-column-end: 2;
    color: gray;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}

.mainTeacherZoneCompletedLessons{
    border-radius: 10px;
    grid-row-start: 4;
    grid-row-end:5;
    grid-column-start: 1;
    grid-column-end: 2;
    color: gray;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}

.mainTeacherZoneCompletedLessonsSpan{

    font-size: 25px;
}

.mainTeacherZoneStudents{
    color: gray;
    border-radius: 10px;
    grid-row-start: 5;
    grid-row-end:6;
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;

}

.mainTeacherZoneStudentsSpan{
      font-size: 25px;
      margin-right: 50px;

}

.mainTeacherZoneStudentsSpanBlur {
    font-size: 25px;
    margin-right: 50px;
    color: transparent; /* Make the text transparent */
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Add a shadow with blur effect */
}

.flexBtn{
    display: flex;
    flex-direction: row;
}

.mainTeacherZoneDivEarnedMoney{
    color: gray;
    border-radius: 10px;
    grid-row-start: 6;
    grid-row-end:7;
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}

.mainTeacherZoneEarnedMoneySpan{
    font-size: 25px;
}

.mainTeacherZoneDivBalance{
    color: gray;
    border-radius: 10px;
    grid-row-start: 7;
    grid-row-end:8;
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}


.mainTeacherZoneBalanceSpan{
    font-size: 25px;
}

.mainTeacherZoneLanguages{
margin-top: 50px;
 
}

.mainTeacherZoneLanguagesUpdateTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainTeacherZoneLanguagesUpdateBottom{
    display: flex;
    flex-wrap: wrap;
}

.mainTeacherZoneLanguagesUpdate{
    margin-left: 50px;
}

.mainTeacherZoneLanguagesItems{
    font-size: 16px;
    color: Purple ;
    font-weight: 500;
    display: flex;
    gap:20px;
}



.updateLanguageBtn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    margin-bottom: 10px;
  }



  .updateLanguageBtn:hover {
    background-color: #408c40; /* Darker shade of the original color */
  }




.mainTeacherZoneSettings{
    font-family: 'Poppins', sans-serif;
    border-radius: 10px;
    grid-row-start: 1;
    grid-row-end:9;
    grid-column-start: 2;
    grid-column-end: 3;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;

}

.mainTeacherZoneSettingsTax{
    margin-top: 20px;
    border-radius: 10px;
    width: 80%;
    height: 50px;
    background-color: #f5f6f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 20px;
    
}

.mainTeacherZoneSettingsTAXPurple{
    color: Purple ;
    font-weight: 900;
}

.mainTeacherZoneSettingsPresentation{
    margin-top: 20px;
    border-radius: 10px;
    width: 80%;
    height: 450px;
    background-color: #f5f6f9;
    display: flex;
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 20px;
    position: relative;
}
.mainTeacherZoneSettingsPresentationText {
    width: 500px;
    max-width: 500px;
    overflow-y: auto;
    color: purple;
    word-wrap: break-word;
  }
  
  .mainTeacherZoneSettingsPresentationText pre {
    margin: 0;
    white-space: pre-wrap;
  }
  
.mainTeacherZoneSettingsPresentationTitle{
    color: lightgray;
    position: absolute;
    top: 0px;
    right:0px;

}

.mainTeacherZoneSettingsVideoPresentation{
    margin-top: 20px;
    border-radius: 10px;
    width: 80%;
    height: 50px;
    background-color: #f5f6f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 20px;
}

.mainTeacherZoneSettingsVideoPresentationCODE{
    color: Purple ;
    font-weight: 900;
}
/* For WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f5f6f9;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  

  .updateBtn {
    margin-top: 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .update:hover {
    background-color: #408c40; /* Darker shade of the original color */
  }

@media (max-width: 767px) {

    .mainTeacherZoneDiv{
        padding-left: 5px;
        width: 94vw;
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 100px 60px 60px 60px 60px 60px 60px 120px auto auto 
        
      
    }

    .mainTeacherZoneLanguages{
        grid-row-start: 8;
        grid-row-end:9;
        grid-column-start: 1;
        grid-column-end: 2;
        
    }
    
    .mainTeacherZoneSettings{
        grid-row-start: 9;
        grid-row-end:10;
        grid-column-start: 1;
        grid-column-end: 2;
        background-color: #f5f6f9;
        padding-left: 0;
        padding-right: 0;
        
    }

    .mainTeacherZoneTeacherSince{
            justify-content: flex-start;
    }




    .mainTeacherZoneSettingsTax{
        grid-row-start: 10;
        grid-row-end:11;
        grid-column-start: 1;
        grid-column-end: 2;
         padding-right: 15px;
         height: 50px;
        width: 400px;
       
         
    }
    .mainTeacherZoneSettingsPresentation{
        margin-top: 30px;
        grid-row-start: 11;
        grid-row-end:12;
        grid-column-start: 1;
        grid-column-end: 2;
        margin-left: 0;
       padding-left: 0;
       width: 420px;
      
   
   }

   .mainTeacherZoneSettingsPresentationText {
    width: 400px;
    max-width: 400px;
    overflow-y: auto;
    color: purple;
    word-wrap: break-word;
  }
  
  .mainTeacherZoneSettingsPresentationText pre {
    margin: 0;
    white-space: pre-wrap;
  }

    .mainTeacherZoneSettingsVideoPresentation{
         width: 400px;
        padding-top: 10px;
        padding-bottom: 10px;
      
    }
}